import {start} from 'ng-starter-pack' // start = starter to load configuration, module = angular default module
import config from './config' // don't remove this unless you provide configuration here
import pages from './page' // don't remove this
import './script' // don't remove this

import './src/style/base.scss'

start(
  Object.assign(config, {
    template: pages
  })
)

