export default {
  http: {
    url: 'https://cltm-stage.herokuapp.com',
  },
  locale: 'id',
  err: null,
  page: {
    routes: {
      '/': 'index',
    },
    default: '404',
    err404: '404',
    header: 'header',
    footer: 'footer',
  },
  data: {
    map: {
      center: [100.49834776977931, -1.167864863581329],
      zoom: 12,
    }
  }
}