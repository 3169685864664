import { module } from 'ng-starter-pack'
import config from '../../config'

module.run(['$http', '$rootScope', '$window',
  function ($http, $rootScope, $window) {
    String.prototype.send = function (params) {
      let token = 'development'
      $rootScope.fetching = this === "objects.list"
      if ($rootScope.account) {
        token = $rootScope.account.token
      }
      return new Promise((resolve, reject) => {
        $http.post(
          config.http.url, {
          to: [],
          action: this,
          params: params || {}
        }, {
          headers: {
            'content-type': 'application/json',
            'token': token
          }
        }
        )
          .then(resp => resp.data)
          .then(resp => {
            $rootScope.fetching = false
            if (resp.status) {
              resolve(resp.response.data)
            } else {
              reject(resp)
            }
          })
          .catch(err => {
            $rootScope.fetching = false
            reject({
              status: false,
              message: 'Tidak dapat terhubung dengan server. Silahkan periksa koneksi internet kamu'
            })
          })
      })
    }

    window.remoteStorage = {
      setItem: function (obj, callback) {
        'objects.put'
          .send(obj)
          .then(resp => {
            Object.assign(obj, resp)
            return resp
          })
          .then(resp => {
            if (typeof callback === 'function') {
              callback(resp)
            }
          })
      },
      del: function(obj, callback) {
        'objects.put'
        .send(obj)
        .then(resp => {
          Object.assign(obj, resp)
          return resp
        })
        .then(resp => {
          if (typeof callback === 'function') {
            callback(resp)
          }
        })
      },
      get: function(obj) {
        if (obj.id) {
          'objects.get'
            .send({ id: obj.id })
            .then(resp => {
              Object.assign(obj, resp)
            });
        }
      },
      as: function(obj, param) {
        let action = param
        let params = typeof param === 'string' ? { id: obj.id } : param || {}

        if (param.id) {
          action = param.action
        }
        if (param.action) {
          action = param.action
          params = param || {}
        }

        action
          .send(params)
          .then(resp => {
            Object.assign(obj, resp)
          });
      },
      list: function(obj, param, callback) {
        let action = 'objects.list'
        let params = typeof param === 'string' ? { type: param } : param || {}

        if (param.id) {
          action = param.action
        }
        if (param.action) {
          action = param.action
          params = param || {}
        }

        action
          .send(params)
          .then(resp => {
            Object.assign(obj, resp)
            if (typeof callback === 'function') {
              callback(resp)
            }
          });
      }
    }
  }
])