import { module } from 'ng-starter-pack'
import config from '../../config'

import 'ol/ol.css';
import Map from 'ol/Map'
import View from 'ol/View'
import { Select } from 'ol/interaction'
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer'
import { OSM, Vector as VectorSource } from 'ol/source'
import { GeoJSON } from 'ol/format'
import { fromLonLat } from 'ol/proj'
import { Style, Stroke, Fill, Text, Circle, Icon } from 'ol/style'

const markerIcon = new Style({
  image: new Icon(({
    anchor: [0.5, 1],
    src: "/assets/marker.png  "
  }))
});

const emptyIcon = new Style({
  image: new Circle({
    stroke: new Stroke({
      width: 0
    }),
    fill: new Fill({
      color: 'rgba(199, 59, 48, 0)',
    })
  })
});

const styles = {
  icon: [
    new Style({
      image: new Circle({
        radis: 7,
        stroke: new Stroke({
          color: 'rgba(199, 59, 48, 1)',
          width: 2
        }),
        fill: new Fill({
          color: 'rgba(199, 59, 48, 1)',
        })
      })
    })
  ],
  red: [
    new Style({
      radis: 7,
      stroke: new Stroke({
        color: 'rgba(199, 59, 48, .4)',
        width: 4
      }),
      fill: new Fill({
        color: 'rgba(199, 59, 48, .1)',
      })
    }),
  ],
  black: [
    new Style({
      radis: 7,
      stroke: new Stroke({
        color: 'rgba(156, 156, 156, .4)',
        width: 2
      }),
      fill: new Fill({
        color: 'rgba(156, 156, 156, .1)',
      })
    }),
  ]
}

const colors = {
  'red': '199, 59, 48',
  'black': '156, 156, 156',
  'A': '31, 163, 35',
  'B': '48, 202, 52',
  'C': '128, 222, 98',
  'D': '186, 222, 98',
  'E': '232, 215, 87',
  'F': '236, 136, 33',
  'G': '236, 70, 33',
  'H': '234, 22, 22',
}

function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

function setPointStyle(feature, resolution) {
  let text = feature.getProperties()['nilai'];
  let zoom = window.map.getView().getZoom();
  text = zoom > 11 ? 'Rp. ' + formatNumber(parseInt(text)) : ''
  let radius = 8; //zoom > 12 ? 8 : 2;
  return new Style({
    image: new Circle({
      radius: radius,
      fill: new Fill({ color: 'red' }),
    }),
    text: new Text({
      font: 'bold 14px sans-serif',
      fill: new Fill({ color: '#000' }),
      offsetY: -15,
      offsetX: 30,
      text: text
    })
  })
}

function setStyle(feature, resolution) {
  let color, text
  let dash = []
  let width = 1

  switch (true) {
    case feature.getProperties()['Nagari'] !== undefined:
      color = colors.black
      dash = [5]
      text = feature.getProperties()['Nagari'];
      break;

    case feature.getProperties()['zona'] !== undefined:
      var r = getZntRange(feature.getProperties()['nilai']);
      color = colors[r.class]
      width = 0
      if (feature.getProperties()['nilai'] != 0) {
        text = feature.getProperties()['zona'] + '. Rp.' + formatNumber(parseInt(feature.getProperties()['nilai']));
      }
      break;

    default:
      color = colors.red
      text = ''
      break;
  }
  return [
    new Style({
      stroke: new Stroke({
        color: `rgba(${color}, .9)`,
        lineDash: dash,
        width: width
      }),
      fill: new Fill({
        color: `rgba(${color}, .35)`
      }),
      text: new Text({
        font: 'bold 15px sans-serif',
        fill: new Fill({ color: '#000' }),
        text: text
      })
    })
  ]
}

const featureOption = {
  dataProjection: 'EPSG:4326',
  featureProjection: 'EPSG:3857'
}

const zntRange = [
  { min: -1, max: 100000, class: 'A' },
  { min: 100000 + 1, max: 200000, class: 'B' },
  { min: 200000 + 1, max: 300000, class: 'C' },
  { min: 300000 + 1, max: 400000, class: 'D' },
  { min: 400000 + 1, max: 500000, class: 'E' },
  { min: 500000 + 1, max: 600000, class: 'F' },
  { min: 600000 + 1, max: 700000, class: 'G' },
  { min: 700000 + 1, max: 99999999999, class: 'H' },
]

function getZntRange(n) {
  try {
    const harga = parseInt(n)
    return zntRange.filter(el => {
      if ((harga > el.min) && (harga <= el.max)) {
        return el
      }
    })[0]
  } catch (ex) {
    console.error(ex)
  }

}

module.controller('edit', ['$scope',
  function ($scope) {
    $scope.editor = {}
    $scope.data = {}

    $scope.searchResultFeature = null

    { // map define
      $scope.map = {
        edit: new VectorLayer({
          source: new VectorSource({ wrapX: false })
        }),
        osm: new TileLayer({
          source: new OSM()
        }),
        adm: new VectorLayer({
          source: new VectorSource({
            projection: 'EPSG:4326',
            url: '/assets/admin.geojson',
            format: new GeoJSON(),
          }),
          style: setStyle
        }),
        znt: new VectorLayer({
          source: new VectorSource({
            projection: 'EPSG:4326',
            url: '/assets/znt.geojson',
            format: new GeoJSON(),
          }),
          style: setStyle
        }),
        point: new VectorLayer({
          source: new VectorSource({
            projection: 'EPSG:4326',
            url: '/assets/point.geojson',
            format: new GeoJSON(),
          }),
          style: emptyIcon
        })
      }

      $scope.ol = new Map({
        target: 'map',
        layers: [
          $scope.map.osm,
          $scope.map.adm,
          $scope.map.znt,
          $scope.map.point,
        ],
        view: new View({
          center: fromLonLat(config.data.map.center),
          zoom: config.data.map.zoom
        })
      });
    }

    window.map = $scope.ol


    $scope.addDetailInteraction = function () {
      $scope.editor.select = new Select({
        filter: function (f, l) {
          if (f.get('tipe') === 'zona') {
            $scope.info = {
              zona: f.get('zona'),
              nilai: f.get('nilai'),
              nilai_lama: f.get('nilai_lama'),
              min: f.get('min'),
              max: f.get('max'),
              class: getZntRange(f.get('nilai')).class.toLowerCase(),
              sebaran: f.get('sebaran')
            }
            $scope.info.kenaikan = (($scope.info.nilai - $scope.info.nilai_lama) / $scope.info.nilai) * 100;
            $scope.$evalAsync();
          }
        }
      })

      $scope.ol.addInteraction($scope.editor.select)
    }

    $scope.addDetailInteraction()

    $scope.$watch('search', val => {
      if (val === '') {
        $scope.map.point.getSource().forEachFeature(f => {
          f.setStyle(emptyIcon)
        })
      }
    })

    $scope.doSearch = function (e) {
      if (e.keyCode === 13) {
        let pattern = /^(\-?\d+(\.\d+)?),\s*(\-?\d+(\.\d+)?)$/
        if ($scope.search.match(pattern)) {
          const xy = $scope.search.split(",")
          $scope.ol.getView().animate({
            center: fromLonLat([xy[1], xy[0]]),
            zoom: 16,
          })

          $scope.map.point.setVisible(true)
          $scope.map.point.getSource().forEachFeature(f => {
            f.setStyle(markerIcon)
            f.getGeometry().setCoordinates(fromLonLat([xy[1], xy[0]]))
          })
        } else {
          alert("Invalid Coordinate")
        }
      }
    }
  }
])
